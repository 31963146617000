<template>
  <div>
    <search
      @submit="submitSearch"
      :root-id="rootId"
      :parent-id="parentId"
    />

    <a-table
      size="middle"
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :pagination="false"
      row-key="id"
    >
      <div slot="name" slot-scope="name, record">
        <router-link :to="'/documents/category/' + record.id + '/info'"> {{ name }} </router-link>
      </div>

      <span slot="actions" slot-scope="text, record">
        <a @click="showEditModal(record.id)">编辑</a>
        <a-divider type="vertical" />
        <a @click="deleteCategory(record.id)" class="delete">删除</a>
      </span>
    </a-table>

    <!-- 编辑 -->
    <edit
      v-if="isShowEditModal"
      :visible.sync="isShowEditModal"
      :root-id="rootId"
      :parent-id="parentId"
      :id="editingId"
      @completed="fetchData"
    />

    <Pagination
      :current="current"
      :total="total"
      @handleChange="handleChange"
      v-show="total > 20"
    />
  </div>
</template>

<script>
import Search from '@/pages/document/category/Search'
import Pagination from "@/components/Pagination"
import { DocumentCategoryModel } from "@/models/document_category.js"
const documentCategoryModel = new DocumentCategoryModel()
import formatTime from '@/utils/formatTime'
import { mapMutations } from 'vuex'

export default {
  name: 'ThirdDocumentCategoriesList',
  components: {
    Search,
    Pagination,
    Edit: () => import('@/pages/document/category/Edit')
  },
  data() {
    return {
      query: {},
      data: [],
      loading: true,
      editingId: 0,
      total: 0,
      current: 1,
      isShowNewModal: false,
      isShowEditModal: false
    }
  },
  created() {
    this.fetchData()
  },
  computed: {
    columns() {
      return [
        {
          title: '三级分类名称',
          dataIndex: 'name'
        },
        {
          title: '文档数量',
          dataIndex: 'document_count'
        },
        {
          title: '创建时间',
          dataIndex: 'created_at',
          customRender: formatTime,
        },
        {
          title: '操作',
          dataIndex: 'actions',
          scopedSlots: { customRender: 'actions' }
        },
      ]
    },
    rootId() {
      return parseInt(this.$route.params.id)
    },
     parentId() {
      return parseInt(this.$route.params.parentId)
    }
  },
  methods: {
    submitSearch(search) {
      this.current = 1
      this.query = Object.assign({},
        this.$options.data().query,
        search)
      this.fetchData()
    },

    handleChange(page) {
      this.current = page
      this.fetchData()
    },

    deleteCategory(id) {
      const vm = this
      this.$confirm({
        title: '确定删除当前种类吗?',
        content: '',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          documentCategoryModel.destroy(id).then((res) => {
            if (res.code === 0) {
              vm.$notification.open({
                message: '提示',
                description: res.message,
                duration: 2
              })
              vm.fetchData()
            }
          })
        }
      })
    },

    showEditModal(id) {
      this.editingId = id
      this.isShowEditModal = true
    },

    ...mapMutations({
      parentName: 'PARENT_NAME'
    }),
    
    fetchData() {
      this.loading = true
      const data = {
        ...this.query,
        page: this.current,
        root_id: this.rootId,
        parent_id: this.parentId
      }
      documentCategoryModel.index(data).then(res => {
        if (res.code === 0) {
          this.data = res.result
          this.total = res.total
        }
        this.loading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.delete {
  color: red;
}
</style>

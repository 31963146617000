<template>
  <div>
    <a-form
      class="sfm-nested-search-form"
      :form="form"
      @submit="handleSearch"
    >
      <a-row :gutter="12">
        <a-col :span="3">
          <a-form-item>
            <a-input
              v-decorator="['name']"
              placeholder="请输入种类名称"
            >
            </a-input>
          </a-form-item>
        </a-col>

        <a-col :span="6">
          <a-form-item>
            <a-button type="primary" html-type="submit" class="search-button">
              搜索
            </a-button>
            <a-button type="primary" @click="handleReset" class="search-button">
              刷新
            </a-button>
            <a-button type="primary" @click="showNewModal" class="search-button">
              新增
            </a-button>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>


    <!-- 新增 -->
    <new
      v-if="isShowNewModal"
      :visible.sync="isShowNewModal"
      :root-id="rootId"
      :parent-id="parentId"
      @completed="handleReset"
    />
  </div>
</template>

<script>
export default {
  name: 'DocumentCategorySearch',
  components: {
    New: () => import('@/pages/document/category/New'),
  },
  props: {
    rootId: {
      type: Number,
      required: true
    },
    parentId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'document_category_search' }),
      isShowNewModal: false
    }
  },
  methods: {
    handleSearch(e) {
      e.preventDefault()
      this.$emit('submit', this.form.getFieldsValue())
    },

    showNewModal() {
      this.isShowNewModal = true
    },

    handleReset() {
      this.form.resetFields()
      this.$emit('submit', {})
    }
  }
}
</script>

<style lang="scss" scoped>
.search-button {
  margin-right: 10px;
}
</style>
